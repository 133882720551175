<template>
  <a-layout style="height: 100vh;" >
    <a-layout-sider v-if="is_logged_in" theme="light">
      <a-divider />
      <a-menu v-model:selectedKeys="selectedKeys" theme="light" mode="inline" >
        <div v-for="item, i in sidebars" :key="i" >
          <a-menu-item v-if="item.route" :key="i">
            <router-link :to="item.route" >
              <span class="material-symbols-outlined">{{ item.icon }}</span>
              <span>{{ item.title }}</span>
            </router-link>
          </a-menu-item>
          <a-sub-menu v-else :key="item.key">
            <template #title>
              <span>
                <span class="material-symbols-outlined">{{ item.icon }}</span>
                <span>{{ item.title }}</span>
              </span>
            </template>
            <a-menu-item v-for="sub in item.submenu" :key="sub.key">
              <router-link :to="sub.route" >
                {{ sub.title }}
              </router-link>  
            </a-menu-item>
          </a-sub-menu>
        </div>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header v-if="is_logged_in" >
        <div style="float: right;">
          <span>
            <span style="margin-right: 0.5rem;font-size: 24px;color: white;">{{ user?.username }}</span>
              <a-avatar :size="42">
                <template #icon>
                  <img :src="user?.avatar" />
                </template>
              </a-avatar>
          </span>
          <a style="color: white;margin-left: 2rem;" @click="logOut()" class="material-symbols-outlined">logout</a>
        </div>
      </a-layout-header>
      <a-layout-content style="height: 100%;margin: 24px;background-color: #FFFFFF;border-radius: 30px;padding: 24px;">
        <router-view
          :logo="logo"
        />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
export default {
    name: "App",
    data() {
        return {
            logo: "https://cdn.discordapp.com/attachments/1105519635077550119/1105519679566520340/345332412_1602800100220556_7017575407330795394_n.png",
            is_logged_in: localStorage.getItem("token"),
            selectedKeys: ['0'],
            sidebars: [
              { title: "รายการเบิกเงิน", route: "/", icon: "payments", key: "1" },
              { title: "รายการ Payback", route: "/payback", icon: "currency_exchange", key: "6" },
              { title: "จัดการพนักงาน", route: "/employee", icon: "badge", key: "2" },
              { title: "จัดการบริษัท", key: "3", icon: "cases", submenu: [
                { title: "บริษัทลูกค้า", icon: "cases", key: "5", route: "/company" },
                { title: "กลุ่ม Partner", icon: "group", key: "4", route: "/partner" },
              ] }
            ],
            user: JSON.parse(localStorage.getItem('profile'))
        };
    },
    methods: {
      logOut() {
        localStorage.removeItem("token");
        location.reload()
      },
      async init_notification() {
        Notification.requestPermission()
      },
    },
    mounted() {
      this.init_notification()
    }
}
</script>