import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Order',
    component: () => import('../views/Order.vue') 
  },
  {
    path: '/employee',
    name: 'Employee',
    component: () => import('../views/Employee.vue')
  },
  {
    path: '/partner',
    name: 'Partner',
    component: () => import('../views/Partner.vue')
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('../views/Company.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/payback',
    name: 'Payback',
    component: () => import('../views/Payback.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const is_logged = localStorage.getItem('token')
  if (to.name !== 'Login' && !is_logged) next({ name: 'Login' })
  else next()
})

export default router
